import React from 'react';
import BlogPost from './BlogPost'; // Asegúrate de que la ruta sea correcta
import Blog from './Blog'; // Asegúrate de que la ruta sea correcta
import NewsSection from './NewsSection'; // Asegúrate de que la ruta sea correcta
import CybersecurityNewSection from './CybersecurityNewsSection'; // Asegúrate de que la ruta sea correcta

const Home = () => {
  return (
    <div className="home">

    <CybersecurityNewSection />
      <NewsSection />
      
      <div className="blog-posts">
        <BlogPost title="Título del Post 1" content="Contenido del post 1." date="01/01/2024" />
        <BlogPost title="Título del Post 2" content="Contenido del post 2." date="02/01/2024" />
        <BlogPost title="Título del Post 3" content="Contenido del post 3." date="02/01/2024" />
        <Blog />
        {/* Agrega más BlogPost aquí */}
      </div>
    </div>
  );
}

export default Home;
