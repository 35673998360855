// scrollHandler.js
export const handleScroll = () => {
    const headerElement = document.querySelector('.header');
    if (window.scrollY > 50) {
      headerElement.classList.add('scrolled'); // Añade la clase cuando haces scroll
    } else {
      headerElement.classList.remove('scrolled'); // La quita cuando estás arriba
    }
  };
  
  export const initScrollHandler = () => {
    window.addEventListener('scroll', handleScroll);
  
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  };
  