import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CSS/NewsSection.css'; // Archivo de estilos para las tarjetas

const TechnologyNewsSection = () => {
  const [news, setNews] = useState([]); // Almacena las noticias
  const [visibleNews, setVisibleNews] = useState(10); // Número de noticias visibles inicialmente
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado para manejar errores

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get('https://seriware.com/proxy_tech.php'); // Cambiar a proxy_tech.php
        console.log('Response:', response.data); // Verifica la estructura de la respuesta

        if (response.data.articles && response.data.articles.length > 0) {
          setNews(response.data.articles); // Almacena las noticias en el estado
          setLoading(false); // Finaliza la carga
        } else {
          throw new Error('No se encontraron artículos.');
        }
      } catch (error) {
        console.error('Error fetching the news:', error);
        setError('No se pudieron cargar las noticias. Intenta de nuevo más tarde.');
        setLoading(false); // Finaliza la carga en caso de error
      }
    };

    fetchNews(); // Ejecuta la función cuando el componente se monta
  }, []);

  const loadMoreNews = () => {
    setVisibleNews(prevVisibleNews => prevVisibleNews + 10); // Muestra 10 noticias más
  };

  if (loading) {
    return <p>Cargando noticias...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="news-section">
      <h2>Últimas Novedades en Tecnología</h2>
      <div className="news-grid">
        {news.slice(0, visibleNews).map((article, index) => (
          <div key={index} className="news-item">
            <img 
              src={article.urlToImage || 'ruta/a/imagen/default.jpg'} 
              alt={article.title} 
              className="news-image" 
            />
            <h3>{article.title}</h3>
            <p>{article.description}</p>
            <a 
              href={article.url} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="read-more"
              onClick={() => console.log(article.url)} // Log de la URL
            >
              Leer más
            </a>
          </div>
        ))}
      </div>
      {visibleNews < news.length && (
        <button onClick={loadMoreNews} className="view-more">
          Ver más noticias
        </button>
      )}
    </section>
  );
}

export default TechnologyNewsSection;
