import React from 'react';

const BlogPost = ({ title, content, date }) => {
  return (
    <div className="blog-post">
      <h3>{title}</h3>
      <p>{content}</p>
      <small>{date}</small>
    </div>
  );
}

export default BlogPost;
