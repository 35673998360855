import React, { useEffect, useState } from 'react';
import './CSS/Header.css'; // Asegúrate de que la ruta sea correcta
import logo from './Logo.png'; // Asegúrate de que el logo esté en tu carpeta
import { useLocation } from 'react-router-dom'; // Importa useLocation para detectar la ruta
import { initScrollHandler } from './scrollHandler'; // Importamos el manejo del scroll

const Header = () => {
  const location = useLocation(); // Obtener la ruta actual
  const [isOpen, setIsOpen] = useState(false); // Estado para controlar el menú

  useEffect(() => {
    const cleanupScroll = initScrollHandler(); // Inicia el manejo del scroll

    return () => {
      cleanupScroll(); // Limpia el evento cuando el componente se desmonte
    };
  }, []);

  // Función para cambiar el contenido del header según la ruta
  const getHeaderText = () => {
    switch (location.pathname) {
      case '/CybersecurityNewsSection':
        return { title: 'Noticias de Ciberseguridad', subtitle: 'Mantente al día con la seguridad digital' };
      case '/NewsSection':
        return { title: 'Noticias de Tecnología', subtitle: 'Las últimas novedades en el mundo tech' };
      case '/blog':
        return { title: 'MY BLOG', subtitle: 'Explora nuestros artículos' };
      case '/contact':
        return { title: 'Contáctanos', subtitle: 'Estamos aquí para ayudarte' };
      default:
        return { title: 'HACK ADN CYBER', subtitle: 'Las últimas novedades de tecnología' };
    }
  };

  const headerText = getHeaderText(); // Llamada a la función para obtener los textos

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Cambia el estado del menú al hacer clic
  };

  return (
    <header className="header">
      <div className="top-bar">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
          <h1 className='domain'>S<span>3</span>RIWARE</h1>
        </div>
        
        <div className="menu-toggle" onClick={toggleMenu}>
          {/* Icono de menú hamburguesa */}
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>

        <nav className={`navbar ${isOpen ? 'active' : ''}`}>
          <ul className="nav-links">
            <li><a href="/">Inicio</a></li>
            <li><a href="/CybersecurityNewsSection">Cyber-News</a></li>
            <li><a href="/NewsSection">Tecno-News</a></li>
            <li><a href="/blog">Blog</a></li>
            <li><a href="/contact">Contacto</a></li>
            <li>
              <select>
                <option value="es">ES</option>
                <option value="en">EN</option>
              </select>
            </li>
          </ul>
        </nav>
      </div>

      <div className="header-text">
        <h1>{headerText.title}</h1> {/* Título dinámico */}
        <p>{headerText.subtitle}</p> {/* Subtítulo dinámico */}
      </div>
    </header>
  );
};

export default Header;
