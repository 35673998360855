import React from 'react';
import './CSS/Blog.css';



const Blog = () => {
    return (

          <div >
         
            
         <nav className="navbarr">
          <ul className="nav2-links">
            <li><a href="/NewsSection">Proyectos</a></li>
            <li><a href="/blog">Importante</a></li>
            <li><a href="/contact">Contacto</a></li>
           
            <li>
            </li>
          </ul>
        </nav>

        
          </div>
    

      );
}

export default Blog;
